.form {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	margin-top: 150px;
}
.form_travel_master {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}
.form_create_new_employee {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
}
.form_login {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.form_update {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	margin-top: 0px;
}

.form-container {
	width: 100%;
	padding: 20px 15px;
	background-color: white;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
}

.form-container .header {
	flex: 20%;
	display: grid;
	place-items: center;
}

.form-container .footer {
	flex: 20%;
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
}

.sign-up-container {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.sign-up-container input {
	margin: 5px;
	width: 200px;
	height: 40px;
	padding-left: 5px;
	font-size: 20px;
}

.personal-info-container {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.personal-info-container input {
	margin: 5px;
	width: 200px;
	height: 40px;
	padding-left: 5px;
	font-size: 20px;
}

/* PROGRESS BAR */
.progressbar {
	width: 100%;
	height: 10px;
	background-color: white;
	margin-bottom: 28px;
}

.progressbar div {
	width: 33.3%;
	height: 100%;
	background-image: linear-gradient(180deg, #2267a2 0%, #0d4675 100%);
}

.other-info-container {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.other-info-container input {
	margin: 5px;
	width: 200px;
	height: 40px;
	padding-left: 5px;
	font-size: 20px;
}

input {
	border: 2px solid rgb(98, 0, 255);
	border-radius: 5px;
}

input:focus {
	border: 3px solid rgb(98, 0, 255);
}

/* ===== Time Line ===== */
/* ===================== */
.timeline {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
}
.timeline__event {
	background: #fff;
	position: relative;
	display: flex;
	border-radius: 8px;
}
.timeline__event__title {
	font-size: 1.2rem;
	line-height: 1.4;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 1.5px;
}
.timeline__event__content {
	padding: 20px;
	background: rgb(230, 230, 230);
	border-radius: 0px 10px 10px 0px;
}
.last_child {
	padding: 20px;
	background: rgb(230, 230, 230);
	border-radius: 10px;
}

.timeline__event__icon {
	border-radius: 8px 0 0 8px;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-basis: 40%;
	font-size: 2rem;
}
.timeline__event__icon i {
	position: absolute;
	top: 50%;
	left: -65px;
	font-size: 2.5rem;
	transform: translateY(-50%);
}
.timeline__event__description {
	flex-basis: 60%;
}
.timeline__event:after {
	content: '';
	width: 2px;
	height: 100%;
	background: #9251ac;
	position: absolute;
	top: 60%;
	left: -3.5rem;
	z-index: -1;
}
.timeline__event:before {
	content: '';
	width: 1rem;
	height: 1rem;
	position: absolute;
	background: #f6a4ec;
	border-radius: 100%;
	left: -3rem;
	top: 45%;
	transform: translateY(-50%);
	border: 2px solid #9251ac;
}
.timeline__event--type2:before {
	background: #b8b8b8;
	border-color: #9e9e9e;
}
.timeline__event--type2:after {
	background: #9e9e9e;
}
.timeline__event--type2 .timeline__event__icon {
	background: #9e9e9e;
	color: #9e9e9e;
}
.timeline__event--type2 .timeline__event__title {
	color: #9e9e9e;
}
.timeline__event:last-child:after {
	content: none;
}

@media (max-width: 786px) {
	.timeline__event {
		flex-direction: column;
	}

	.timeline__event__icon {
		border-radius: 4px 4px 0 0;
	}
}
